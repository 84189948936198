import {AppThemeModeType, AppThemeType} from './AppThemeProvider';

type ThemeColorTypes = {
  '--primary-110': string;
  '--primary-100': string;
  '--primary-90': string;
  '--primary-60': string;
  '--primary-30': string;
  '--primary-15': string;
  '--primary-10': string;
  '--primary-5': string;
  '--primary-0': string;
  '--secondary-100': string;
  '--secondary-90': string;
  '--secondary-60'?: string;
  '--secondary-30'?: string;
  '--secondary-15'?: string;
  '--secondary-10': string;
  '--secondary-5'?: string;
  '--tertiary-100': string;
};

export const themes: Record<AppThemeType, Record<AppThemeModeType, ThemeColorTypes>> = {
  housewarePink: {
    light: {
      // '--primary-110': '#6b205a',
      // //Base color
      // '--primary-100': '#6d1d4f',
      // '--primary-90': '#7d365f',
      // '--primary-60': '#b17e9a',
      // '--primary-30': '#d4b9c6',
      // '--primary-15': '#e3d0d9',
      // '--primary-10': '#f1e7ec',
      // '--primary-5': '#f8f3f5',

      '--primary-110': '#3d52e1',
      '--primary-100': '#435cfe',
      '--primary-90': '#5a72ff',
      '--primary-60': '#8e9aff',
      '--primary-30': '#bcc6ff',
      '--primary-15': '#d8e0ff',
      '--primary-10': '#ebf0ff',
      '--primary-5': '#f7f9ff',
      '--primary-0': '#f7f9ff40',

      // '--secondary-100': '#FD36A3',
      // '--secondary-90': '#55853b',
      // '--secondary-10': '#e9efe5',

      // '--secondary-110': '#e03091',
      '--secondary-100': '#FD36A3',
      '--secondary-90': '#ff5fb3',
      // '--secondary-60': '#ff94cc',
      // '--secondary-30': '#ffcae5',
      // '--secondary-15': '#ffe3f0',
      '--secondary-10': '#fff0f7',
      // '--secondary-5': '#fff8fb',

      '--tertiary-100': '#E2BC52',
    },
  },

  longbottomBlue: {
    light: {
      '--primary-110': '#3144d9',
      '--primary-100': '#425eff',
      '--primary-90': '#536eff',
      '--primary-60': '#879cff',
      '--primary-30': '#bbc8ff',
      '--primary-15': '#d5ddff',
      '--primary-10': '#dde4ff',
      '--primary-5': '#e4eaff',
      '--primary-0': '#e4eaff40',

      '--secondary-100': '#a736ec',
      '--secondary-90': '#ae4aee',
      '--secondary-60': '#c586f4',
      '--secondary-30': '#e0c3fa',
      '--secondary-15': '#efe1fd',
      '--secondary-10': '#f5ebfe',
      '--secondary-5': '#faf5ff',

      '--tertiary-100': '#00a455',
    },
  },
};
