import {AppThemeModeType, AppThemeType} from './AppThemeProvider';
import {themes} from './themes';

const getNeutralColors = (themeType: AppThemeModeType) => {
  if (themeType === 'light')
    return {
      '--neutral-100': '#1c273b',
      '--neutral-95': '#282f44',
      '--neutral-90': '#34384c',
      '--neutral-85': '#404155',
      '--neutral-80': '#4b4a5d',
      '--neutral-75': '#575466',
      '--neutral-70': '#635e6f',
      '--neutral-65': '#6e6878',
      '--neutral-60': '#7a7281',
      '--neutral-55': '#857d8a',
      '--neutral-50': '#918894',
      '--neutral-45': '#9c939d',
      '--neutral-40': '#a79ea7',
      '--neutral-35': '#b3aab1',
      '--neutral-30': '#beb5bb',
      '--neutral-25': '#c9c1c6',
      '--neutral-20': '#d4cdd1',
      '--neutral-15': '#dfd9dc',
      '--neutral-10': '#e9e6e7',
      '--neutral-5': '#f4f2f3',
      '--neutral-0': '#ffffff',
    };
  else
    return {
      '--neutral-0': '#1c1e21',
      '--neutral-5': '#25272a',
      '--neutral-10': '#2f3033',
      '--neutral-15': '#38393c',
      '--neutral-20': '#424245',
      '--neutral-25': '#4c4c4f',
      '--neutral-30': '#575659',
      '--neutral-35': '#616063',
      '--neutral-40': '#6c6b6d',
      '--neutral-45': '#777578',
      '--neutral-50': '#828082',
      '--neutral-55': '#8d8b8d',
      '--neutral-60': '#989698',
      '--neutral-65': '#a3a2a3',
      '--neutral-70': '#aeadae',
      '--neutral-75': '#bab9ba',
      '--neutral-80': '#c6c5c5',
      '--neutral-85': '#d1d0d1',
      '--neutral-90': '#dddddd',
      '--neutral-95': '#e9e9e9',
      '--neutral-100': '#f5f5f5',
    };
};

const housewareColors = {
  '--border': 'var(--neutral-10)',
  '--dark-border': 'var(--neutral-20)',
  '--background': 'var(--neutral-0)',
  '--dark-foreground': 'var(--neutral-10)',
  '--light-foreground': 'var(--neutral-0)',
  '--disabled-bg': 'var(--neutral-10)',

  '--danger': '#cf1322',
  '--success': '#3f8600',

  '--heading-text': 'var(--neutral-100)',
  '--primary-text': 'var(--neutral-80)',
  '--secondary-text': 'var(--neutral-50)',
  '--tertiary-text': 'var(--neutral-30)',
  '--link-text': 'var(--primary-100)',
  '--disabled-text': 'var(--neutral-30)',
};

export const getThemeColors = ({
  mode,
  theme,
}: {
  mode: AppThemeModeType;
  theme: AppThemeType;
}) => {
  const neutralColors = getNeutralColors(mode);
  return {
    ...neutralColors,
    ...housewareColors,
    ...themes.longbottomBlue[mode],
  };
};
