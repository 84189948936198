import {forceLogoutUser} from '../store/useUserServices';
import {getAuthHeaders, removeAuthToken} from './auth';

// fetch wrapper with auto logout on 401

type CustomFetchConfig = Partial<RequestInit>;

export default async function $fetch(
  url: string,
  customConfig: CustomFetchConfig = {},
  headers: CustomFetchConfig['headers'] = {}
): Promise<any> {
  const authHeaders = getAuthHeaders();
  // Check if body is FormData and adjust headers accordingly
  const isFormData = customConfig.body instanceof FormData;
  if (Object.keys(authHeaders).length === 0) {
    forceLogoutUser();
    return false;
  }

  const config: RequestInit = {
    method: 'GET',
    ...customConfig,
    headers: {
      ...(!isFormData ? authHeaders : {Authorization: authHeaders.Authorization}),
      ...headers,
    },
  };
  const res = await fetch(url, config);
  // if jwt isn't valid logout the user
  if (res.status === 401) {
    removeAuthToken();
    forceLogoutUser();
    return false;
  }

  return res;
}
