import * as React from 'react';
import {Navigate, Route, Routes} from 'react-router-dom';

import DomainRedirect from '../../pages/Auth/DomainRedirect';
import useAppStoreSelector from '../store/useAppStoreSelector';

const AuthenticateCallback = React.lazy(() => import('../../pages/Auth/AuthCallback'));
const AuthRedirect = React.lazy(() => import('../../pages/Auth/AuthRedirect'));
const SelectOrg = React.lazy(() => import('../../pages/Auth/SelectOrg'));

const ConnectWarehouseRoutes = React.lazy(() =>
  import('../../pages/Connections/ConnectWarehouse')
);

const AuthenticatedRoutesWithLayout = React.lazy(() =>
  import('./AuthenticatedRoutesWithLayout')
);

export default function AllRoutes() {
  const {user, orgUsers} = useAppStoreSelector((s) => s.state);
  const isCurrentUserAdmin =
    orgUsers?.find((item) => item.id === user.id)?.user_role === 'admin';

  return (
    <Routes>
      <Route path="/auth" element={<AuthenticateCallback />} />
      <Route
        path="/connect-warehouse/*"
        element={
          isCurrentUserAdmin ? <ConnectWarehouseRoutes /> : <Navigate to="/workspaces" />
        }
      />
      <Route path="/auth_redirect" element={<AuthRedirect />} />
      <Route path="/domain_redirect" element={<DomainRedirect />} />
      <Route path="/select-org" element={<SelectOrg />} />
      <Route path="*" element={<AuthenticatedRoutesWithLayout />} />
    </Routes>
  );
}
