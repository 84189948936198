import * as React from 'react';
import {Route, Routes} from 'react-router-dom';
import DomainRedirect from '../../pages/Auth/DomainRedirect';

import PageLoader from '../layout/PageLoader';

const Authenticate = React.lazy(() => import('../../pages/Auth/Authenticate'));
const AuthenticateCallback = React.lazy(() => import('../../pages/Auth/AuthCallback'));

export default function NonAuthenticatedRoutes() {
  return (
    <Routes>
      <Route path="/auth" element={<AuthenticateCallback />} />
      <Route path="/domain_redirect" element={<DomainRedirect />} />
      <Route
        path="/wait-redirecting"
        element={
          <PageLoader
            style={{width: '100vw', height: '100vh'}}
            loadingText="Please hold tight! You are being redirected to your customised Houseware URL"
          />
        }
      />
      <Route path="*" element={<Authenticate />} />
    </Routes>
  );
}
