// theme/themeConfig.ts
import type {ThemeConfig} from 'antd';
import {getThemeColors} from './appColors';
import {AppThemeModeType, AppThemeType} from './AppThemeProvider';

export function getTheme(themeMode: AppThemeModeType, theme: AppThemeType): ThemeConfig {
  const themeAppColors = getThemeColors({mode: themeMode, theme: theme});

  const themeConfig: ThemeConfig = {
    token: {
      // Seed Token
      colorPrimary: themeAppColors['--primary-100'],
      borderRadius: 8,
      fontSize: 14,
      colorBorderSecondary: themeAppColors['--border'],
      colorSplit: themeAppColors['--border'],
      colorText: themeAppColors['--primary-text'],
      colorTextHeading: themeAppColors['--heading-text'],
      colorTextDescription: themeAppColors['--secondary-text'],
      fontFamily: 'Inter',
      colorLink: themeAppColors['--link-text'],
      colorTextDisabled: themeAppColors['--disabled-text'],
      colorError: themeAppColors['--danger'],
      colorBgContainerDisabled: themeAppColors['--disabled-bg'],
      colorInfo: themeAppColors['--primary-100'],
      wireframe: false,
      colorTextBase: themeAppColors['--primary-text'],
    },
    components: {
      Typography: {
        titleMarginBottom: 'revert',
        colorTextDescription: themeAppColors['--secondary-text'],
        colorLink: themeAppColors['--link-text'],
        colorLinkHover: themeAppColors['--link-text'],
      },
      Menu: {
        activeBarBorderWidth: 0,
        horizontalItemHoverBg: themeAppColors['--background'],
        itemBg: themeAppColors['--light-foreground'],
        itemSelectedBg: themeAppColors['--link-text'],
        itemSelectedColor: themeAppColors['--primary-10'],
        itemHeight: 30,
        itemBorderRadius: 6,
        groupTitleColor: themeAppColors['--tertiary-text'],
        groupTitleFontSize: 12,
        itemPaddingInline: 2,
        itemMarginInline: 2,
        iconMarginInlineEnd: 8,
      },
      Avatar: {
        colorTextPlaceholder: themeAppColors['--secondary-60'],
      },
      Button: {
        borderColorDisabled: themeAppColors['--border'],
        dangerColor: themeAppColors['--background'],
        primaryShadow: 'none',
        primaryColor: themeAppColors['--primary-5'],
        colorPrimary: themeAppColors['--link-text'],
        borderRadius: 6,
        paddingInline: 12,
        fontWeight: 500,
        colorError: themeAppColors['--danger'],
        defaultBg: themeAppColors['--background'],
        defaultColor: themeAppColors['--primary-text'],
        defaultBorderColor: themeAppColors['--border'],
      },
      Segmented: {
        itemActiveBg: themeAppColors['--background'],
        itemSelectedBg: themeAppColors['--background'],
        //trackBg: themeAppColors['--dark-foreground'],
        itemColor: themeAppColors['--primary-text'],
      },
      Card: {
        colorBgContainer: themeAppColors['--light-foreground'],
      },
      Select: {
        clearBg: themeAppColors['--light-foreground'],
        selectorBg: themeAppColors['--light-foreground'],
        colorBgElevated: themeAppColors['--light-foreground'],
        colorPrimaryHover: themeAppColors['--border'],
        colorPrimary: themeAppColors['--border'],
        optionSelectedBg: themeAppColors['--link-text'],
        optionSelectedColor: themeAppColors['--primary-10'],
        optionActiveBg: themeAppColors['--dark-foreground'],
        colorBorder: themeAppColors['--border'],
        borderRadiusSM: 4,
      },
      Radio: {
        // colorPrimaryTextHover: themeAppColors["--primary-text"],
        //colorPrimary: themeAppColors["--dark-foreground"],
        // colorPrimaryHover: themeAppColors["--dark-foreground"],
        buttonBg: themeAppColors['--light-foreground'],
        buttonColor: themeAppColors['--primary-text'],
        buttonCheckedBg: themeAppColors['--primary-10'],
        buttonSolidCheckedBg: themeAppColors['--primary-10'],
        buttonSolidCheckedActiveBg: themeAppColors['--dark-foreground'],
        buttonSolidCheckedColor: themeAppColors['--primary-90'],
        buttonSolidCheckedHoverBg: themeAppColors['--primary-10'],

        // On Hover over selected
        colorBorder: themeAppColors['--border'],
        borderRadius: 5,
      },
      Popover: {
        colorBgElevated: themeAppColors['--light-foreground'],
      },
      Input: {
        activeBorderColor: themeAppColors['--border'],
        hoverBorderColor: themeAppColors['--border'],
        colorBorder: themeAppColors['--border'],
        activeShadow: 'none',
        colorPrimaryHover: themeAppColors['--tertiary-text'],
        colorPrimary: themeAppColors['--secondary-text'],
        colorBgContainer: themeAppColors['--light-foreground'],
        colorTextPlaceholder: themeAppColors['--tertiary-text'],
      },
      Tooltip: {
        colorBgSpotlight: themeAppColors['--neutral-90'],
        fontSize: 12,
        borderRadius: 8,
        colorText: themeAppColors['--neutral-10'],
        boxShadow: 'none',
      },
      Dropdown: {
        colorBgElevated: themeAppColors['--light-foreground'],
      },
      Divider: {
        colorText: themeAppColors['--tertiary-text'],
        colorTextHeading: themeAppColors['--tertiary-text'],
        fontSize: 4,
        fontSizeLG: 10,
        margin: 0,
        marginLG: 0,
      },
      Drawer: {
        colorBgElevated: themeAppColors['--background'],
      },
      Modal: {
        contentBg: themeAppColors['--background'],
        headerBg: themeAppColors['--background'],
      },
      Table: {
        borderColor: themeAppColors['--border'],
        headerBg: themeAppColors['--light-foreground'],
        colorBgContainer: themeAppColors['--light-foreground'],
        rowHoverBg: themeAppColors['--dark-foreground'],
        headerSplitColor: themeAppColors['--background'],
        lineWidth: 0.1,
        headerColor: themeAppColors['--secondary-text'],
      },
      Tag: {
        defaultBg: themeAppColors['--dark-foreground'],
        defaultColor: themeAppColors['--primary-text'],
        colorBorder: themeAppColors['--border'],
      },
      Layout: {
        siderBg: themeAppColors['--background'],
        bodyBg: themeAppColors['--background'],
        footerBg: themeAppColors['--background'],
        headerBg: themeAppColors['--background'],
      },
    },
  };

  return themeConfig;
}
