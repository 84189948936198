const EVENT_TYPE_RULE_CREATED = 'Addition of rules';
const EVENT_TYPE_FAKE_DOOR_CLICKED = 'Fake door clicked';
const EVENT_TYPE_RULE_EDITED = 'Rule edited';

export default class Analytics {
  static identify(user) {
    if (user) {
      const userData = {
        id: user?.id,
        email: user?.email,
        name: user?.name,
        organization_id: user?.organization?.id,
        organization_name: user?.organization?.name,
        user_id: user?.id,
      };
      analytics.identify(String(user?.uuid), userData);
      return;
    }
    analytics.identify();
  }

  static track(event, payload = {}) {
    analytics.track(event.name, {
      ...payload,
      category: event.category,
      property: event.properties,
    });
  }

  static page(event, properties) {
    analytics.page(event.category, event.name, properties);
  }

  static ruleCreated(payload) {
    this.track(EVENT_TYPE_RULE_CREATED, payload);
  }

  static ruleEdited(payload) {
    this.track(EVENT_TYPE_RULE_EDITED, payload);
  }

  static fakeDoorClicked(doorIdentifier) {
    this.track(EVENT_TYPE_FAKE_DOOR_CLICKED, {
      door: doorIdentifier,
    });
  }
}
