const LOCAL_STORAGE_AUTH_KEY = '__houseware_ndsfienh_token__';
export const LOCAL_STORAGE_MESSAGE_TOKEN = 'houseware_conversation_token_v1';

export default function clearReactQueryCache(key: string) {
  // remove react query offline cache stored in localstorage
  localStorage.removeItem(key);
}

export function getAuthToken() {
  const token = localStorage.getItem(LOCAL_STORAGE_AUTH_KEY);
  return token || null;
}

export function setAuthToken(token: string) {
  localStorage.setItem(LOCAL_STORAGE_AUTH_KEY, token);
  return true;
}

export function removeAuthToken() {
  localStorage.removeItem(LOCAL_STORAGE_AUTH_KEY);
  localStorage.removeItem(LOCAL_STORAGE_MESSAGE_TOKEN);
  return true;
}

type HeaderWithToken =
  | {
      Accept: string;
      'Content-Type': string;
      Authorization: string;
    }
  | Record<string, never>;

export function getAuthHeaders(): HeaderWithToken {
  const token = getAuthToken();
  if (!token) {
    return {};
  }

  return {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  };
}
