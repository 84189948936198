const apiHostURL = process.env.REACT_APP_API_HOST || '';
const isProductionApp = apiHostURL.includes('production');

const demoUserIds = [
  'cus_c6477c72370044d3844eb8205716f4e3',
  'cus_e8148d730c2d436f9dc11f6651cbd8ae',
  'cus_181f893ed132406dbc44b94619f02592',
];

const config = {
  isProductionApp,
  apiHostURL,
  googleAuthClientId:
    '1065482580621-2plp9sagfk0e2v02e1bq7hibrga4fu0f.apps.googleusercontent.com',
  commandBarApiKey: process.env.REACT_APP_COMMAND_BAR_API_KEY,
  airtableSecureDataSharingApiKey:
    process.env.REACT_APP_AIRTABLE_SECURE_DATA_SHARING_API_KEY,
  logRocketKey: isProductionApp ? 'yhs54x/houseware-app' : 'yhs54x/houseware-develop',
  sentryDsnKey: isProductionApp
    ? 'https://f88f35441e96414cbff171c7b104f18a@o1155050.ingest.sentry.io/6235240'
    : '',
  environment: isProductionApp ? 'production' : 'development',
  authRedirectURL:
    process.env.NODE_ENV === 'development'
      ? `${window.location.origin}`
      : process.env.REACT_APP_AUTH_REDIRECT_DOMAIN,
  launchDarklyKey: process.env.REACT_APP_LAUNCH_DARKLY_KEY || '63806fc0ccbc38119aa8b186',
  reactQueryOfflineCacheKey: 'REACT_QUERY_OFFLINE_CACHE_V3',
  knockPublicKey: process.env.REACT_APP_KNOCK_PUBLIC_API_KEY,
  knockFeedChannelId: process.env.REACT_APP_KNOCK_FEED_CHANNEL_ID,
  localhost: ['localhost', '127.0.0.1', '192.168.1.101'],
  demoUserIds,
  defaultDateFilterDays: window.location.origin.includes('launchdarkly') ? 30 : 3,
  defaultDateFilterWindow: {
    unit: 'day',
    value: window.location.origin.includes('launchdarkly') ? 30 : 3,
  },
  demoOrgId: isProductionApp ? 33 : 78,
  minimumSupportedWidth: 700,
};

export default config;
