import {Button, Result} from 'antd';
import {CSSProperties, useEffect} from 'react';
import Analytics from '../../app/services/analytics/Analytics';
import appEvents from '../../app/services/analytics/appEvents';

type Props = {
  errorTitle?: string;
  errorText?: string;
  retryText?: string;
  style?: CSSProperties;
};

export default function Error500({errorTitle, errorText, retryText, style}: Props) {
  useEffect(() => {
    Analytics.page(appEvents.ERROR_PAGE_VIEWED, {
      error_details: errorText || errorTitle,
    });
  }, [errorText, errorTitle]);

  return (
    <Result
      style={{
        height: '100vh',
        width: '100%',
        background: 'var(--background)',
        paddingTop: '10%',
        ...(style || {}),
      }}
      status="500"
      title={errorTitle || 'Error'}
      subTitle={errorText || 'Sorry, something went wrong.'}
      extra={
        <Button type="primary" onClick={() => window.location.reload()}>
          {retryText || 'Reload'}
        </Button>
      }
    />
  );
}
