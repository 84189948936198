import {Spin, Typography} from 'antd';
import {CSSProperties} from 'react';

type ObjectMap<T> = {
  [key: string]: T;
};

const styles: ObjectMap<CSSProperties> = {
  loaderStyles: {
    display: 'flex',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    background: 'var(--background)',
  },
  loadingText: {
    marginTop: 24,
  },
};

type PageLoaderProps = {
  loadingText?: string;
  style?: CSSProperties;
};

export default function PageLoader({loadingText, style}: PageLoaderProps) {
  return (
    <div style={{...styles.loaderStyles, ...(style || {})}}>
      <Spin delay={200} size="large" />
      {loadingText ? (
        <Typography.Text style={styles.loadingText}>{loadingText}</Typography.Text>
      ) : null}
    </div>
  );
}
